import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { stub as $t } from '@nbcnews/analytics-framework';
import { product as ProductPropType } from 'lib/CustomPropTypes';
import { isOnSale, getProductOfferPrices } from 'lib/productOfferPriceHelpers';
import AIMS_FLAVORS from 'lib/aimsFlavors';
import Link from 'components/Link';
import TeasePicture from 'components/TeasePicture';
import { trackEcommerceShopClick } from 'lib/trackEcommerceShopClick';
import styles from './styles.module.scss';

/**
 * @typedef {object} Product
 * @property {string} id - The product ID.
 * @property {string} label - The product label.
 * @property {object} teaseImage - The product tease image.
 * @property {string} name - The product name.
 * @property {Array<Offer>} offers - The product offers.
 * @property {string} [nameOverride] - The product name override.
 */

/**
 * @typedef {object} Offer
 * @property {string} externalUrl - The product URL.
 * @property {Seller} seller - The product seller.
 * @property {object} prices - The product prices.
 */

/**
 * @typedef {object} Seller
 * @property {string} name - The seller name.
 */

/**
 * Card component to display product information.
 *
 * @param {object} props - The component props.
 * @param {Product} props.product - The product data.
 * @param {string} props.vertical - The vertical category.
 */
export function Card({ product, vertical }) {
  const {
    id,
    label,
    teaseImage,
    name: productName,
    offers: [{
      externalUrl: productUrl,
      seller: {
        name: sellerName,
      } = {},
      prices,
    } = {}] = [],
    nameOverride,
  } = product;

  if (!id || !productName || !sellerName) {
    return null;
  }

  const dataClickTrackingID = 'mbt_quicktake_click';

  const learnMoreLink = useRef();

  const productPrices = getProductOfferPrices({ prices });

  /**
   *
   */
  const trackEcommerceClick = () => trackEcommerceShopClick({ vertical, product });

  return (
    <div className={styles.container}>
      <div className={styles.teaseImage}>
        <TeasePicture
          computedValues={{ teaseImage }}
          responsiveFlavors={{
            s: AIMS_FLAVORS.FOCAL_100x100,
          }}
        />
      </div>
      <div className={styles.rightCol}>
        <div className={styles.superlative}>
          {label}
        </div>
        <div className={styles.productName}>
          <Link
            data-product-id={id}
            href={productUrl}
            rel="sponsored noopener noreferrer"
            target="_blank"
            className={styles.productLink}
            onClick={trackEcommerceClick}
          >
            {nameOverride || productName}
          </Link>
        </div>
        <div className={styles.offer}>
          <div className={classNames(styles.prices, {
            [styles.onSale]: isOnSale({
              salePrice: productPrices.sale,
              listPrice: productPrices.list,
            }),
          })}
          >
            <div className={styles.salePrice}>
              {`$${productPrices.sale}`}
            </div>
            <div className={styles.listPrice}>
              {`$${productPrices.list}`}
            </div>
          </div>
          <div className={styles.seller}>
            <Link
              data-product-id={id}
              className={styles.sellerName}
              href={productUrl}
              rel="sponsored noopener noreferrer"
              target="_blank"
              tabIndex="-1"
              onClick={trackEcommerceClick}
            >
              {sellerName}
            </Link>
          </div>
        </div>
        <div className={styles.learnMoreContainer}>
          <a
            className={styles.learnMore}
            href={`#${id}`}
            data-product-id={id}
            data-testid="learn-more"
            ref={learnMoreLink}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              const linkEl = learnMoreLink.current;
              const element = document.getElementById(linkEl.dataset.productId);
              element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
              $t('track', dataClickTrackingID, { action: 'learnmore' });
              trackEcommerceClick();
            }}
          >
            Learn more
            <svg width="8" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.downArrow}>
              <path d="M1.70931 6.875L4.79683 9.96252L4.79683 0.75L5.38016 0.75L5.38016 9.96252L8.5885 6.875L9.00098 7.28748L5.0885 10.9583L1.29683 7.28748L1.70931 6.875Z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {
  product: ProductPropType.isRequired,
  vertical: PropTypes.string.isRequired,
};
