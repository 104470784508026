/* eslint-disable react/jsx-props-no-spreading */

import React, { useCallback, useContext, useRef } from 'react';
import classNames from 'classnames';

import Ad from 'components/Ad';
import EmbedWidget from 'components/EmbedWidget';
import BlockQuote from 'components/BlockQuote';
import FeatureLink from 'components/FeatureLink';
import { ImageLiftout } from 'components/ImageLiftout';
import { InlineImage } from 'components/InlineImage';
import InlineVideo from 'components/InlineVideo';
import LiftOut from 'components/LiftOut';
import Link from 'components/Link';
import { NewsletterSignupInline } from 'components/NewsletterSignup/Inline';
import NewsvinePoll from 'components/NewsvinePoll';
import { LazyListicleProduct } from 'components/ListicleProduct';
import PullQuote from 'components/PullQuote';
import { ContentLinkCard } from 'components/ContentLinkCard';
import SummaryBox from 'components/SummaryBox';
import { FAQEmbed } from 'components/FAQ';
import { TaboolaRecoReel } from 'components/TaboolaRecoReel';
import { ButtonShowMore } from 'components/ButtonShowMore';
import { RunExperiment } from 'components/RunExperiment';
import { RUN_EXPERIMENT } from 'lib/brandFeatures';
import ShoppingCart from 'components/ShoppingCart';
import { QuickTake } from 'components/QuickTake';
import { CuratedList } from 'components/CuratedList';
import { Statement } from 'components/Statement';
import { Subhed } from 'components/ChatCard/ChatCardFactCheck/Subhed';
import ContentModule from 'components/ContentModule';

import { trackEcommerceShopClick } from 'lib/trackEcommerceShopClick';
import { VerticalContext } from 'lib/ContextTypes';
import LazyLoad from 'react-lazyload';
import {
  getDebugComponent,
  getRecommendationComponent,
} from './getComponent';

import { TYPE as TABOOLA_RECO_REEL_TYPE } from './getSections/insertTaboolaRecoReel';

export const {
  LAMBDA_TEST = false,
} = process.env;

const {
  getBodyItemComponent: {
    NewsletterSignupInline: runExperimentNewsletterSignupInlineCampaignNumber,
  },
} = RUN_EXPERIMENT;

/* eslint-disable react/prop-types */
/**
 * Simple component for rendering HTML elements.
 *
 * @param {object} props - The properties for the element component.
 * @param {string} props.className - The class to apply to the element.
 * @param {string} props.element - The type of HTML element (e.g., p, h2, etc.).
 * @param {string} props.html - The HTML content.
 * @param {string} props.id - The ID of the HTML element.
 * @param {number} [props.index] - The index of the element in the section markup.
 * @param {object[]} [props.sectionMarkup] - The markup of the entire article.
 * @returns {React.ReactElement} The rendered HTML element.
 */
export const Element = ({
  className,
  element,
  html,
  id,
  index = 0,
  sectionMarkup = [],
}) => {
  /** @type {React.MutableRefObject<HTMLElement>} */
  const ref = useRef();
  const vertical = useContext(VerticalContext);

  const trackClick = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const link = ref.current.querySelector('a');

    const hasCommerceEncoding = link?.getAttribute('data-commerce-encoding') === 'true';
    const hasSellerId = link?.getAttribute('data-seller-id');


    if (!(hasCommerceEncoding && hasSellerId)) {
      return;
    }

    // Track commerce affiliate links tied to standalone html elements
    let product = null;

    // Find closest product preceding affiliate link, and retrieve its product data
    for (let i = index; i < sectionMarkup.length; i += 1) {
      const item = sectionMarkup[i];

      if (item.type === 'embeddedProduct') {
        product = item.product;
        break;
      }
    }

    if (!product) {
      return;
    }

    trackEcommerceShopClick({
      vertical,
      product,
    });
  }, []);

  const Type = element ?? 'p';

  return (
    <Type
      ref={ref}
      id={id}
      className={className}
      dangerouslySetInnerHTML={{ __html: html }}
      onClick={trackClick}
    />
  );
};

/* eslint-enable react/prop-types */
/**
 * Renders an HTML element based on the provided item and its properties.
 *
 * @param {object} item - The item containing the element properties.
 * @param {string} item.element - The type of HTML element (e.g., p, h2, etc.).
 * @param {string} item.html - The HTML content to be rendered inside the element.
 * @param {boolean} item.insertEndmark - Flag indicating if an endmark should be inserted.
 * @param {boolean} item.ecommerceEnabled - Flag indicating if ecommerce is enabled.
 * @param {string} item.elementId - The ID of the HTML element.
 * @param {number} i - The index of the element in the section markup.
 * @param {string} path - The path for the link if the element is a heading.
 * @param {object[]} sectionMarkup - The markup of the entire article.
 * @returns {React.ReactElement} The rendered HTML element.
 */
function htmlElement(item, i, path, sectionMarkup) {
  const {
    element, html = [], insertEndmark, ecommerceEnabled, elementId,
  } = item;
  // `Type` must be capitalized for JSX to recognize it as a dynamic tag name.
  const Type = element ?? 'P';

  const className = classNames({
    endmark: insertEndmark,
    'break-above': Type === 'ul',
    'body-ul': Type === 'ul',
    'body-ol': Type === 'ol',
    'body-list-el': Type === 'ul' || Type === 'ol',
  });

  if (!html || !html.length) {
    return <Type key={i} className={className} />;
  }

  if (Type === 'blockquote') {
    const text = html.replace(/(<([^>]+)>)/ig, '');

    if (/class=['"]pullquote["']/.test(html)) {
      return <PullQuote key={i} text={text} />;
    }

    return <BlockQuote key={i} text={text} />;
  }

  if (
    ['h2', 'h3', 'h4'].includes(Type.toLowerCase())
    && !html.includes('<a')
    && ecommerceEnabled
  ) {
    const cleanTitle = html.replace(/[^A-Za-z0-9]/g, '');
    const anchorId = `anchor-${elementId || cleanTitle}`;
    return (
      <Link key={i} href={`${path}#${anchorId}`} id={anchorId} className="scrollLink">
        <Type className={className} dangerouslySetInnerHTML={{ __html: html }} />
      </Link>
    );
  }

  return (
    <Element
      key={i}
      id={elementId ? `anchor-${elementId}` : undefined}
      index={i}
      className={className}
      html={html}
      element={element}
      sectionMarkup={sectionMarkup}
    />
  );
}

/**
 * Renders an image component based on the provided item and its properties.
 *
 * @param {object} item - The item containing the image properties.
 * @param {object} item.presentation - The presentation properties of the image.
 * @param {string} item.presentation.size - The size of the image (e.g., 'fullwidth').
 * @param {string} item.elementId - The ID of the image element.
 * @param {number} i - The index of the image in the section markup.
 * @param {boolean} lazyLoadSection - Flag indicating if the section should be lazy-loaded.
 * @returns {React.ReactElement} The rendered image component.
 */
function getImageComponent(item, i, lazyLoadSection) {
  const { presentation, elementId } = item;
  // lazyload images by default
  // images should eagerly load if they've been flagged as being in the first section
  // and they are within the first few segments
  const isLazyLoaded = lazyLoadSection || i > 4;
  if (presentation.size === 'fullwidth') {
    return (
      <ImageLiftout
        content={item}
        key={i}
        lazyloaded={isLazyLoaded}
        elementId={elementId}
      />
    );
  }
  return (
    <InlineImage
      content={item}
      key={i}
      presentation={presentation.size}
      lazyloaded={isLazyLoaded}
      elementId={elementId}
    />
  );
}

/**
 * Renders a widget component based on the provided item and its properties.
 *
 * @param {object} item - The item containing the widget properties.
 * @param {object} item.presentation - The presentation properties of the widget.
 * @param {string} item.presentation.size - The size of the widget.
 * @param {object} item.widget - The widget properties.
 * @param {string} item.widget.name - The name of the widget.
 * @param {object} item.widget.properties - The properties of the widget.
 * @param {number} i - The index of the widget in the section markup.
 * @param {Function} setShowHiddenMarkupAndEmbeds - Callback to show hidden markup and embeds.
 * @param {boolean} isArticleEcommerceEnabled - Flag indicating if ecommerce is enabled for the article.
 * @param {string} [vertical=''] - The vertical context.
 * @param {string} parentId - The ID of the element that the button controls.
 * @param {string} parentHeadline - The headline of the widget's parent.
 * @returns {React.ReactElement|null} The rendered widget component or null if no widget is provided.
 */
function getWidgetComponent(
  item,
  i,
  setShowHiddenMarkupAndEmbeds,
  isArticleEcommerceEnabled,
  vertical = '',
  parentId,
  parentHeadline,
) {
  if (!item?.widget) {
    return null;
  }

  const {
    presentation: {
      size,
    },
    widget,
    widget: {
      name,
      properties = {},
    },
  } = item;

  switch (name) {
    case 'nbc_blockquote': {
      const {
        'nbcblockquote-text': text,
        'nbcblockquote-attributionlink': source,
        'nbcblockquote-attribution': attribution,
      } = properties;
      return (
        <BlockQuote
          attribution={attribution}
          elementId={item.elementId}
          key={i}
          source={source}
          text={text}
        />
      );
    }

    case 'nbc_liftout': {
      const {
        'liftout-text': text,
      } = properties;
      return (
        <LiftOut key={i} size={size} text={text} />
      );
    }

    case 'nbc_pullquote': // intentional fall-through
    case 'nbc_pull_quote': {
      const {
        'pullquote-attribution': attribution,
        'pullquote-text': text,
        quip,
      } = properties;
      return (
        <PullQuote attribution={attribution} key={i} quip={quip} text={text} size={size} />
      );
    }

    case 'nbc_featuredlink':
      return <FeatureLink key={i} widget={widget} />;

    case 'IFRAMELY_EXTERNAL_EMBED': {
      const url = widget?.properties['canonical-url'] || '';
      const isNbcDomain = /www\.(nbcnews|today|msnbc)\.com/.test(url);
      const hasHeadline = !!widget?.properties?.headline;
      if (isNbcDomain && hasHeadline && isArticleEcommerceEnabled) {
        const transformedWidget = {
          properties: {
            'featuredlink-headline': widget.properties.headline,
            'featuredlink-linktext': widget.properties.description,
            'featuredlink-image': widget.properties['image-url'],
            'featuredlink-linkurl': widget.properties['canonical-url'],
          },
        };
        return <FeatureLink key={i} widget={transformedWidget} />;
      }
      return <EmbedWidget {...item} key={i} />;
    }

    case 'newsvine_large_poll':
      return <NewsvinePoll key={i} widget={widget} />;

    case 'CUSTOM_EMBED': {
      switch (properties.embed.type) {
        case 'BLOCKQUOTE': {
          const {
            attribution,
            attributionUrl: source,
            text,
          } = properties.embed;
          return (
            <BlockQuote
              attribution={attribution}
              elementId={item.elementId}
              key={i}
              source={source}
              text={text}
            />
          );
        }

        case 'FACT_CHECK_STATEMENT': {
          const {
            attribution,
            attributionUrl,
            text,
          } = properties.embed;
          return (
            <>
              <Subhed>Statement</Subhed>
              <Statement
                attribution={attribution}
                attributionUrl={attributionUrl}
                key={i}
                text={text}
              />
            </>
          );
        }

        case 'LIFT_OUT': {
          const { text } = properties.embed;
          return (
            <LiftOut elementId={item.elementId} key={i} size={size} text={text} />
          );
        }

        case 'PULL_QUOTE': {
          const { attribution, quip, text } = properties.embed;
          return (
            <PullQuote
              elementId={item.elementId}
              attribution={attribution}
              key={i}
              quip={quip}
              text={text}
              size={size}
            />
          );
        }

        case 'HTML': {
          const { html } = properties.embed;
          return (
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={{ __html: html }} />
          );
        }

        case 'SUMMARY_BOX': {
          const { headline, items } = properties.embed;
          return (
            <SummaryBox
              elementId={item.elementId}
              headline={headline}
              items={items}
              key={i}
              vertical={vertical}
            />
          );
        }

        case 'FAQ': {
          const { faqs, headline } = properties.embed;
          return (
            <FAQEmbed elementId={item.elementId} id={i} faqs={faqs} headline={headline} />
          );
        }

        case 'SHOW_MORE_BUTTON': {
          const { displayShowMore } = properties.embed;

          return displayShowMore && (
            <ButtonShowMore
              size={size}
              title="Show More"
              onShowMoreClick={setShowHiddenMarkupAndEmbeds}
              key="show-more-button"
              controls={`${parentId}-overflow-content`}
              headline={parentHeadline}
            />
          );
        }

        case 'ALL_PURPOSE_EMBED': {
          const advEmbItem = {
            ...item,
            widget: {
              ...(item?.widget ?? {}),
              properties: {
                ...(item?.widget?.properties ?? {}),
                'embed-code': properties?.embed?.code ?? '',
              },
            },
          };
          return <EmbedWidget {...advEmbItem} key={i} />;
        }
        case 'COMMERCE_QUICK_TAKES': {
          const {
            embed: {
              title,
              subtitle,
              products,
            },
          } = properties;

          return (
            <QuickTake
              elementId={item.elementId}
              title={title}
              subTitle={subtitle}
              products={products}
              vertical={vertical}
            />
          );
        }

        default:
          return getDebugComponent(item, i);
      }
    }

    default:
      return <EmbedWidget {...item} key={i} />;
  }
}

/**
 * Renders a body item component based on the provided item and its properties.
 *
 * @param {object} params - The parameters for the function.
 * @param {object} params.item - The item containing the component properties.
 * @param {boolean} params.isArticleEcommerceEnabled - Flag indicating if ecommerce is enabled for the article.
 * @param {number} params.i - The index of the item in the section markup.
 * @param {string} params.path - The path for the link if the item is a heading.
 * @param {string} params.articlePublishDate - The publish date of the article.
 * @param {string} params.vertical - The vertical context.
 * @param {boolean} [params.lazyLoadSection=true] - Flag indicating if the section should be lazy-loaded.
 * @param {Function} params.setShowHiddenMarkupAndEmbeds - Callback to show hidden markup and embeds.
 * @param {string} params.articleId - The ID of the article.
 * @param {string} params.articleTrackingId - The tracking ID of the article.
 * @param {object[]} [params.sectionMarkup=[]] - The markup of the entire article.
 * @param {boolean} params.isCard - Flag indicating if the item is a card.
 * @param {string} params.parentId - The ID of the element's parent.
 * @param {string} params.parentHeadline - The headline of the body element's parent.
 * @returns {React.ReactElement|null} The rendered body item component or null if no component is provided.
 */
function getBodyItemComponent({
  item,
  isArticleEcommerceEnabled,
  i,
  path,
  articlePublishDate,
  vertical,
  lazyLoadSection = true,
  setShowHiddenMarkupAndEmbeds,
  articleId,
  articleTrackingId,
  sectionMarkup = [],
  isCard,
  parentId,
  parentHeadline,
}) {
  switch (item.type) {
    case 'markup':
      return htmlElement(item, i, path, sectionMarkup);
    case 'embeddedCuratedList': {
      const { curatedList } = item;
      if (curatedList && articleId) {
        const {
          id,
          headline,
          body,
          ecommerceMetadata,
          ecommerceEnabled,
          content,
        } = curatedList;
        return (
          <CuratedList
            id={id}
            elementId={item.elementId}
            articleId={articleId}
            title={headline?.primary}
            description={body?.map((b) => b.html)?.join('')}
            curatedListTrackingId={ecommerceMetadata?.trackingId}
            articleTrackingId={articleTrackingId}
            ecommerceEnabled={ecommerceEnabled}
            disclaimerOverride={ecommerceMetadata?.disclaimer?.body[0]?.html}
            insertEndmark={item.insertEndmark}
            items={content?.items}
            path={path}
            shortDisclaimer={ecommerceMetadata?.shortDisclaimer}
          />
        );
      }
      return null;
    }
    case 'embeddedWidget':
      return getWidgetComponent(
        item,
        i,
        setShowHiddenMarkupAndEmbeds,
        isArticleEcommerceEnabled,
        vertical,
        parentId,
        parentHeadline,
      );

    case 'embeddedImage':
      return getImageComponent(item, i, lazyLoadSection);

    case 'embeddedVideo': {
      if (!item.video) {
        return null;
      }

      const inlineVideo = (
        <InlineVideo
          addClassNames={classNames({ endmark: item.insertEndmark })}
          disablePlaylistAutoplay={item.disablePlaylistAutoplay}
          video={item.video}
          presentation={item.presentation}
          key={item.video.id}
          elementId={item.elementId}
        />
      );

      if (LAMBDA_TEST || !isCard) {
        return inlineVideo;
      }

      return (
        <LazyLoad offset={500}>
          {inlineVideo}
        </LazyLoad>
      );
    }
    case 'hr':
      return <hr key={i} />;

    case 'advertisement':
      return <Ad key={i} offsetViewport={100} slot="boxinline" />;

    case 'taboolaReadMoreBelow':
      return <div id="taboolaReadMoreBelow" key={i} />;

    case 'embeddedVideoPlaylist':
      return null;

    case 'embeddedRecipe':
      return <ContentLinkCard elementId={item.elementId} content={item.recipe} key={i} />;

    case 'embeddedSlideshow':
      return <ContentLinkCard content={item.slideshow} key={i} />;

    case 'shoppingCartCarousel':
      return <ShoppingCart article={item.article} vertical={item.vertical} key={i} />;

    case 'embeddedProduct': {
      const { product, presentation, elementId } = item || {};
      if (!product) {
        return null;
      }
      return (
        <LazyListicleProduct
          key={`product-${product.id}`}
          addClassNames={classNames({ endmark: item.insertEndmark })}
          product={product}
          size={presentation?.size ?? null}
          articlePublishDate={articlePublishDate}
          vertical={vertical}
          elementId={elementId}
        />
      );
    }

    case 'embeddedTaxonomy':
      // Hide taxonomy widget. Component rendered in articleBody
      return null;

    case 'externalLink':
      return (
        <p key={i}>
          <Link href={item.url}>Read more here</Link>
          .
        </p>
      );

    case 'inlineNewsletter':
      return (
        <RunExperiment
          key={`ABTestVWO-${i}`}
          campaignNumber={runExperimentNewsletterSignupInlineCampaignNumber[vertical]}
          variantComponents={[
            <NewsletterSignupInline
              {...item.props}
            />,
          ]}
        >
          <NewsletterSignupInline
            shouldThumbnailRender={false}
            {...item.props}
          />
        </RunExperiment>
      );

    case 'recommended': {
      return getRecommendationComponent(item, i, vertical);
    }

    case TABOOLA_RECO_REEL_TYPE: {
      return <TaboolaRecoReel key="taboola-reco-reel" />;
    }

    case 'embeddedContentModule':
      return (
        <ContentModule
          activityMapId="content_embed_viewed"
          htmlElement={htmlElement}
          icid="content_embed"
          item={item}
          path={path}
          vertical={vertical}
        />
      );

    default:
      return getDebugComponent(item, i);
  }
}

export { htmlElement, getBodyItemComponent, getWidgetComponent };
