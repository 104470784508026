import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/Button';

import styles from './styles.module.scss';


/**
 * Renders the ButtonShowMore component.
 *
 * @param {object} props - The properties for the component.
 * @param {string} [props.size] - The size to render the button.
 * @param {Function} [props.onShowMoreClick] - Callback to show data and hide the button on click.
 * @param {string} [props.controls] - The ID of the element that the button controls.
 * @param {string} [props.headline] - The headline to be included in the button label.
 * @returns {React.ReactElement} The rendered ButtonShowMore component.
 */
const ButtonShowMore = ({
  onShowMoreClick, size, controls, headline,
}) => {
  const { t } = useTranslation();

  /**
   *
   */
  const handleClick = () => {
    onShowMoreClick(true);
  };

  return (
    <div
      className={styles.showMoreButtonWrapper}
    >
      <Button
        displayType="expand-down"
        tiltClasses={styles.showMoreButtonTilt}
        additionalClasses={styles.showMoreButton}
        onClick={handleClick}
        title={`${t('Show more')}`}
        size={size}
        ariaControls={controls}
        ariaLabel={headline ? `Show more of post ${headline}` : 'Show more of this blog post'}
      />
    </div>
  );
};

ButtonShowMore.propTypes = {
  onShowMoreClick: PropTypes.func,
  size: PropTypes.string,
  controls: PropTypes.string,
  headline: PropTypes.string,
};

ButtonShowMore.defaultProps = {
  onShowMoreClick: Function.prototype,
  size: '',
  controls: '',
  headline: '',
};

export { ButtonShowMore };
